
import { defineComponent, ref } from 'vue';
import Header from '../components/header/Header.vue';
import User from '../types/User';

export default defineComponent({
  components: { Header },
  setup() {
    const user = ref<User | null>(null);
    const verifier = localStorage.getItem('mal-verifier');

    const getUser = async () => {
      try {
        const url1 = window.location.href.split('code=');

        if (url1.length === 2 && localStorage.getItem('mal-verifier')) {
          const url2 = url1[1].split('&');
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/user/${url2[0]}/${verifier}`
          );
          if (!response.ok) {
            throw Error('No user data available');
          }
          const json = await response.json();
          user.value = json;
          localStorage.setItem('user', JSON.stringify(json));
          window.open(`${process.env.VUE_APP_HOMEPAGE}`, '_self');
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    getUser();

    return { user };
  }
});
